import React from "react";
import styled, { CSSObject } from "styled-components";
import Header from "./Header";

/** TODO
 * - page 마다 height 값 괜찮은지 체크 하기 ( 일단은 100vh로 함. )
 * - width값 최대 몇까지 하는지 체크
 */

interface CommonProps {
  children: React.ReactNode;
  style?: CSSObject;
}

interface LayoutProps extends CommonProps {
  header?: boolean;
  title?: string;
  back?: boolean;
  close?: boolean;
  loading?: boolean;
  disabledMainLayoutPadding?: boolean;
  onCloseClick?: () => void;
  onBackClick?: () => void;
}

/**
 *
 * @param header boolean
 * @param title string
 * @param back boolean
 * @param close boolean
 * @param loading boolean
 * @param disabledMainLayoutPadding boolean
 * @returns
 */
const Layout: React.FC<LayoutProps> = ({
  children,
  header,
  title,
  back,
  close,
  loading,
  disabledMainLayoutPadding,
  onCloseClick,
  onBackClick,
}) => {
  return (
    <StyledDiv>
      {!header && (
        <Header
          title={title}
          back={back}
          close={close}
          onCloseClick={onCloseClick}
          onBackClick={onBackClick}
        />
      )}

      {loading ? (
        <p>loading...</p>
      ) : (
        <StyledMain disabledMainLayoutPadding={disabledMainLayoutPadding}>
          {children}
        </StyledMain>
      )}
    </StyledDiv>
  );
};

export default Layout;

const StyledDiv = styled.div`
  /*  */
  width: 100%;
  min-width: 20rem;
  max-width: 45rem;
  height: 100vh;

  margin: auto;
  display: flex;
  flex-direction: column;

  position: relative;
  background-color: white;
`;

const StyledMain = styled.main<{ disabledMainLayoutPadding?: boolean }>`
  /*  */
  position: relative;
  padding: ${({ disabledMainLayoutPadding }) =>
    disabledMainLayoutPadding ? "" : "0 20px"};
  display: flex;
  flex-direction: column;
`;
