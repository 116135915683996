export default function openEveronApp(simpleCode: string, isOpenAsk: boolean) {
  const path = `https://everon.co.kr?linkTo=Home&simpleCode=${simpleCode}`;
  const encodeUrl = encodeURIComponent(path);
  // 운영
  // const deepLink = `https://everon.page.link/?link=${encodeUrl}&apn=com.chasygo&isi=1330538811&ibi=everon.charger.service&efr=1`;

  // 개발
  // const deepLink = `https://everon.page.link/?link=${encodeUrl}&apn=com.chasygo.dev&isi=6443912721&ibi=everon.charger.service.dev&efr=1`;

  // 최종
  const deepLink = `https://everon.page.link/?link=${encodeUrl}&apn=${process.env.REACT_APP_ANDRIOD_NAME}&isi=${process.env.REACT_APP_IOS_ID}&ibi=${process.env.REACT_APP_IOS_BUNDLE}&efr=1`;

  if (isOpenAsk) {
    if (window.confirm("everon 앱을 여시겠습니까?")) {
      location.href = deepLink;
    }
  } else {
    location.href = deepLink;
  }
}
