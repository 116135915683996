// cSpell:ignore noti
import { colors, typo } from "@styles/index";
import styled from "styled-components";

export const StyledDiv = styled.div`
  /*  */
  padding: 20px;
`;

export const PaymentWrap = styled.div`
  /*  */
  width: 100%;
  height: 138px;
  margin-bottom: 32px;
`;

export const PaymentAmountWrap = styled.div`
  /*  */
  height: 62px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  ${typo.BODY_7}
  color: ${colors.GRAY6};
  margin-bottom: 12px;
`;

export const PaymentAmount = styled.span`
  /*  */
  ${typo.HEADING_2}
  color: ${colors.GRAY2}
`;

export const StyledNoti = styled.div`
  /*  */
  width: 100%;
  background: ${colors.YELLOW5};
  padding: 12px;
  display: flex;
  gap: 6px;
  border-radius: 8px;
`;

export const StyledNotiText = styled.p`
  /*  */
  ${typo.BODY_9}
`;

export const ChargerInformationWrap = styled.div`
  /*  */
  padding: 32px 0;
`;

export const ChargerInformationTitle = styled.p`
  /*  */
  ${typo.HEADING_5}
  margin-bottom: 32px;
`;

export const ChargerInformation = styled.div`
  /*  */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;

  & + & {
    margin-top: 24px;
  }
`;

export const SubTitle = styled.span`
  /*  */
  display: inline-block;
  ${typo.BODY_6}
  color: ${colors.GRAY6};
  /* min-width: 78px; */
  /* max-width: 78px; */
  min-width: 4.875rem;
`;
export const Contents = styled.span`
  /*  */
  ${typo.BODY_6}
  color: ${colors.GRAY2};
  text-align: right;
  width: 80%;
  word-break: keep-all;
`;
