import { ChargingNonMemberPopupProps } from "@components/molecules/Popup/ChargingNonMemberPopUp";
import globalPopupState, { GlobalPopupType } from "@recoil/globalPopup";
import { useSetRecoilState } from "recoil";

export default function useGlobalPopup() {
  const setPopup = useSetRecoilState(globalPopupState);

  const showPopup = ({ popupType, popupProps }: GlobalPopupType) => {
    setPopup({ popupType, popupProps });
  };
  const hidePopup = () => {
    setPopup({ popupType: null, popupProps: null });
  };

  const showLandingPagePopup = ({}: ChargingNonMemberPopupProps) => {
    showPopup({
      popupType: "ChargingNonMemberPopup",
      popupProps: {},
    });
  };

  // const showChargingEndPopup = ({}: ChargingEndPopProps) => {
  //   showPopup({
  //     popupType: "ChargingEndPop",
  //     popupProps: {
  //       handleClose: () => hidePopup(),
  //     },
  //   });
  // };

  return {
    showPopup,
    hidePopup,
    showLandingPagePopup,
    // showChargingEndPopup,
  };
}
