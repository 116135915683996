import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";

interface BasePopupProps {
  children?: React.ReactNode;
  isOpen: boolean;
  handleClose?: () => void;
  isPopupOpen?: boolean;
}

const BasePopup: React.FC<BasePopupProps> = ({
  handleClose,
  children,
  isOpen,
  isPopupOpen = false,
}) => {
  const customStyles = {
    overlay: {
      background: isPopupOpen ? "#00000040" : "transparent",
      height: isPopupOpen ? "" : "0px",
      transition: "all 0.5s ease",
    },
    content: {
      outline: "0",
      height: "auto", // FIXME 100%로 하면 background의 Fn이 작동하지 않음.
    },
  };

  return (
    <Background
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customStyles}
      ariaHideApp={false}
      isPopupOpen={isPopupOpen}
    >
      {children}
    </Background>
  );
};

export default BasePopup;

const Background = styled(ReactModal)<{ isPopupOpen?: boolean }>`
  /*  */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
