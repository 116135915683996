import { LabelTypes } from "src/types/RendingPage";
import DeviceTypeCheck from "./deviceTypeCheck";

const redirectApp = (label: LabelTypes, simpleCode: string) => {
  exeDeepLink(label, simpleCode);
  // checkInstallApp(label);
};

export default redirectApp;

function exeDeepLink(label: LabelTypes, simpleCode: string) {
  let url = "";

  switch (label) {
    case "T맵": {
      url = `http://www.tmap.co.kr/tmap2/mobile/tmap.jsp?scheme=tmap&host=evcharge&extra=%7B%22serviceId%22:%22tmap%22,%22serviceType%22:%22chkQrCode%22,%22payload%22:%22https://qr.everon.co.kr/v1/landing?qrcode=EV${simpleCode}%22%7D`;
      break;
    }
    case "카카오내비": {
      url = `https://carner.kakaomobility.com/web2app/bridge?action=kakaonavi%3A%2F%2Fcar_electro%3Fdata%3D%7B%22code%22%3A%22EV${simpleCode}%22%7D%26ref%3Deveron_qr`;
      break;
    }
  }

  window.location.href = url;
}

function checkInstallApp(label: LabelTypes) {
  const check = setInterval(isHideWeb, 100);

  function isHideWeb() {
    if (document.hidden) {
      clearTimers();
    }
  }

  function clearTimers() {
    clearInterval(check);
  }

  setTimeout(function () {
    const storeURL = getStoreURL(label);
    if (storeURL && window.confirm("스토어로 이동하시겠습니까?")) {
      location.href = storeURL;
    }
  }, 800);
}

function getStoreURL(label: LabelTypes) {
  switch (label) {
    case "T맵": {
      return DeviceTypeCheck() === "android"
        ? "https://play.google.com/store/apps/details?id=com.skt.tmap.ku"
        : "https://apps.apple.com/kr/app/tmap-%EB%8C%80%EB%A6%AC-%EC%A3%BC%EC%B0%A8-%EC%A0%84%EA%B8%B0%EC%B0%A8%EC%B6%A9%EC%A0%84-%ED%82%A5%EB%B3%B4%EB%93%9C%EB%A5%BC-%ED%8B%B0%EB%A7%B5%EC%97%90%EC%84%9C/id431589174";
    }
    default: {
      return undefined;
    }
  }
}
