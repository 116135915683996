import apple from "@assets/icons/apple.svg";
import bell from "@assets/icons/bell.svg";
import check_default from "@assets/icons/check-default.svg";
import check_disabled from "@assets/icons/check-disabled.svg";
import check_gray from "@assets/icons/check-gray.svg";
import check_primary from "@assets/icons/check-primary.svg";
import check_thin_primary from "@assets/icons/check-thin-primary.svg";
import check_selected from "@assets/icons/check-selected.svg";
import check_white from "@assets/icons/check-white.svg";
import close from "@assets/icons/close.svg";
import Epit from "@assets/icons/epit.svg";
import facebook from "@assets/icons/facebook.svg";
import filter_icon from "@assets/icons/filter-icon.svg";
import google from "@assets/icons/google.svg";
import header_close from "@assets/icons/header-close.svg";
import input_error from "@assets/icons/input-error.svg";
import input_success from "@assets/icons/input-success.svg";
import kakao from "@assets/icons/kakao.svg";
import kakao_navi from "@assets/icons/kakao-navi.svg";
import light_bulb from "@assets/icons/light-bulb.svg";
import mapPin_clicked from "@assets/icons/mapPin-clicked.svg";
import mapPin_default from "@assets/icons/mapPin-default.svg";
import mapPin_disabled from "@assets/icons/mapPin-disabled.svg";
import mapPin_used from "@assets/icons/mapPin-used.svg";
import minus_icon from "@assets/icons/minus-icon.svg";
import naver from "@assets/icons/naver.svg";
import tooltip_fill_black from "@assets/icons/pin-tooltip-black.svg";
import tooltip_fill_blue from "@assets/icons/pin-tooltip-blue.svg";
import tooltip_fill_gray from "@assets/icons/pin-tooltip-gray.svg";
import radio_default from "@assets/icons/radio-default.svg";
import radio_disabled from "@assets/icons/radio-disabled.svg";
import radio_selected from "@assets/icons/radio-selected.svg";
import T_map from "@assets/icons/t-map.svg";
import gold_icon from "@assets/icons/gold-icon.svg";
import naver_pay from "@assets/icons/naver-pay.svg";
import credit_card from "@assets/icons/credit-card.svg";
import l_pay from "@assets/icons/l-pay.svg";
import charging_standby_icon from "@assets/icons/charging-standby-icon.svg";
import charging_icon from "@assets/icons/charging-icon.svg";
import payco from "@assets/icons/payco.svg";
import left_arrow from "@assets/icons/left-arrow.svg";
import right_arrow from "@assets/icons/right-arrow.svg";
import shinhan from "@assets/icons/shinhan.svg";
import graph from "@assets/images/graph.png";
import cade_landing from "@assets/icons/card-landing.svg";
import lightning_landing from "@assets/icons/lightning-landing.svg";
import money_landing from "@assets/icons/money-landing.svg";
import naviIcons_Group from "@assets/icons/navi-icon-group.svg";
import naviIcons_Group2 from "@assets/icons/navi-icon-group2.svg";

/* TODO
 *
 * img 한번에 모아서 관리할꺼임
 * icon 폴더 별로 정리?
 */

export const IMAGES = {
  graph,
};

export const ICONS = {
  // button
  bell,

  // // filter icon
  filter_icon,

  // // sns button Logo
  naver,
  apple,
  google,
  kakao,
  facebook,

  // // map pin
  Map_Pin: {
    default: mapPin_default,
    used: mapPin_used,
    disabled: mapPin_disabled,
    clicked: mapPin_clicked,
    tooltip_fill_blue,
    tooltip_fill_black,
    tooltip_fill_gray,
  },

  // // controls
  Controls: {
    check_default,
    check_disabled,
    check_selected,
    check_gray,
    check_white,
    check_primary,
    check_thin_primary,
    radio_default,
    radio_disabled,
    radio_selected,
    // - 버튼
    minus_icon,
  },

  // input
  input_success,
  input_error,
  close,

  // header
  header_close,
  left_arrow,
  right_arrow,

  // rendingPage
  kakao_navi,
  T_map,
  Epit,
  charging_standby_icon,
  gold_icon,
  charging_icon,
  cade_landing,
  lightning_landing,
  money_landing,
  naviIcons_Group,
  naviIcons_Group2,

  // etc
  light_bulb,

  // 결제 icon
  credit_card,
  naver_pay,
  l_pay,
  payco,

  // 은행
  Bank: {
    shinhan,
  },
};
