import { ICONS } from "@assets/index";
import { Icon } from "@components/atoms/Button/IconButton";
import EffectiveInput from "@components/atoms/Input/EffectiveInput";
import BottomButton from "@components/molecules/BottomButton";
import Layout from "@components/organisms/Layout";
import strings from "@constants/strings";
import urls from "@constants/urls";
import useGlobalPopup from "@hooks/useGlobalPopup";
import useRouter from "@hooks/useRouter";
import { termsData } from "@mock/Terms";
import useChargerInfoStore from "@recoil/useChargerInfoStore";
import { colors, typo } from "@styles/index";
import formatNumber from "@utils/numberFormat";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import styled, { css } from "styled-components";

interface FromValueType {
  // 카드번호
  cardNum: string;
  // 유효기간
  validity: string;
  // 비밀번호
  password: string;
  // 주민번호 앞자리
  registrationNumber: string;
  // 사업자 번호
  businessNumber: string;
}

const Payment: React.FC = () => {
  const { register, watch, setFocus, setValue, getValues } =
    useForm<FromValueType>({
      mode: "onChange",
      reValidateMode: "onChange",
    });

  const { replace: routerReplace } = useRouter();
  const { showPopup } = useGlobalPopup();
  const [isCardInputActive, setIsCardInputActive] = useState(false);
  const [isBusinessCheck, setIsBusinessCheck] = useState(false);
  const { chargerInfo } = useRecoilValue(useChargerInfoStore);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const cardValue = watch("cardNum")?.replace(/ /g, "");
  const validityValue = watch("validity")?.replace(" / ", "");
  const passwordValue = watch("password");
  const registrationNumberValue = watch("registrationNumber");
  const businessValue = watch("businessNumber")?.replace(/ /g, "");

  // 카드 input
  const cardInputChange = (text: ChangeEvent<HTMLInputElement>) => {
    let value = text.target.value;
    let formattedValue = "";
    const currentValueLength = watch("cardNum").length;
    const newValueLength = formattedValue.length;
    // remove all non-digit characters from the value
    value = value.replace(/\D/g, "");
    // limit the length of the value to 19 characters
    value = value.slice(0, 19);
    // add a space after every 4 digits
    for (let i = 0; i < value.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedValue += " ";
      }
      formattedValue += value[i];
    }

    // Backspace is pressed and there is a space at the end
    if (
      currentValueLength > newValueLength &&
      watch("cardNum")[currentValueLength - 1] === " "
    ) {
      formattedValue = formattedValue.slice(0, -2);
      // Last character is a space, remove it
    } else if (
      newValueLength > 0 &&
      formattedValue[newValueLength - 1] === " "
    ) {
      formattedValue = formattedValue.slice(0, -1);
    }

    setValue("cardNum", formattedValue);
    if (formattedValue.length === 19) {
      setFocus("validity");
    }
  };

  // 유효기간 input
  const validityOnChange = (text: ChangeEvent<HTMLInputElement>) => {
    let value = text.target.value;
    const currentValueLength = watch("validity").length;
    const newValueLength = value.length;

    if (currentValueLength < newValueLength) {
      if (value.length === 2) {
        value = value.slice(0, 2) + " / " + value.slice(3);
      } else if (value.length === 3 && !value.includes(" / ")) {
        value = value.slice(0, 2) + " / " + value.slice(2);
      }
    } else if (currentValueLength > newValueLength) {
      if (value.length === 5 && value.includes(" / ")) {
        const parts = value.split(" / ");
        value = parts.length === 2 ? parts[0] + parts[1] : parts[0];
      }
    }
    setValue("validity", value);
    if (value.length === 7) {
      setFocus("password");
    }
  };

  // 사업자 번호 input
  const businessNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    const prevValue = value;
    const formattedNumber = formatNumber(value);
    value = formattedNumber;

    if (
      value.length < prevValue.length &&
      (prevValue.length === 4 || prevValue.length === 7)
    ) {
      value = value.trim();
    }

    setValue("businessNumber", value);
  };

  // 약관 open
  const onPayButtonPress = () => {
    const payCardInfo = {
      simpleCode: chargerInfo?.simpleCode,
      socialNumber: isBusinessCheck ? businessValue : registrationNumberValue,
      cardPassword: passwordValue,
      cardNo: cardValue,
      cardExpiredDate: validityValue,
    };

    showPopup({
      popupType: "TermsPopup",
      popupProps: {
        isPopupActive: false,
        TermsData: termsData,
        cardInfo: payCardInfo,
      },
    });
  };

  // input check
  const isPayInfoCheck = useCallback(() => {
    if (
      cardValue?.length >= 14 &&
      validityValue?.length === 4 &&
      passwordValue?.length === 4
    ) {
      if (isBusinessCheck && businessValue?.length === 10) {
        return true;
      } else if (registrationNumberValue?.length === 6) {
        return true;
      }
      return false;
    }

    return false;
  }, [
    businessValue?.length,
    cardValue?.length,
    isBusinessCheck,
    passwordValue?.length,
    registrationNumberValue?.length,
    validityValue?.length,
  ]);

  useEffect(() => {
    if (isPayInfoCheck()) {
      buttonRef?.current?.focus();
    }
  }, [isPayInfoCheck]);

  return (
    <Layout
      title={strings.Title.ChargingNonMember}
      close
      disabledMainLayoutPadding
      onCloseClick={() => {
        routerReplace(urls.LandingPage);
      }}
    >
      <PaymentWrap>
        <TextWrap>
          <Text>신용/체크 카드로 결제 가능해요</Text>
          <StyledDiv
            onClick={() => {
              setIsBusinessCheck(!isBusinessCheck);
              setValue("businessNumber", "");
              setValue("registrationNumber", "");
            }}
          >
            <StyledSpan isActive={isBusinessCheck}>
              <Icon icon={ICONS.Controls.check_white} width={10} height={8} />
            </StyledSpan>
            법인카드
          </StyledDiv>
        </TextWrap>

        <InputWrap>
          <PaymentInputWrap
            isFocus={isCardInputActive}
            onClick={() => {
              setIsCardInputActive(true);
              setTimeout(() => {
                setFocus("cardNum");
              }, 300);
            }}
          >
            <PaymentInputPlaceHolder
              state={isCardInputActive}
              onClick={() => {
                setFocus("cardNum");
              }}
            >
              카드번호
            </PaymentInputPlaceHolder>

            <CardInputWrap state={isCardInputActive}>
              <StyledInput
                isActive
                inputMode="numeric"
                maxLength={19}
                {...register("cardNum", {
                  required: "CARD ERROR",
                })}
                onChange={(text) => cardInputChange(text)}
                autoComplete={"off"}
                onBlur={() => {
                  if (getValues("cardNum").length !== 0) return;
                  setIsCardInputActive(false);
                }}
              />
            </CardInputWrap>
          </PaymentInputWrap>

          <EffectiveInput
            name="validity"
            register={register("validity", {
              required: "Validity ERROR",
            })}
            placeholder="유효기간 (MM/YY)"
            value={watch("validity")}
            setFocus={setFocus}
            maxLength={7}
            numberKeyPad
            inputMode="numeric"
            autoComplete={"off"}
            onChange={(text) => {
              validityOnChange(text);
            }}
          />
          <EffectiveInput
            name="password"
            register={register("password", {
              required: "PASSWORD ERROR",
            })}
            placeholder="비밀번호 4자리"
            value={watch("password")}
            setFocus={setFocus}
            type="password"
            maxLength={4}
            numberKeyPad
            inputMode="numeric"
            autoComplete={"off"}
            onChange={(text) => {
              let value = text.target.value;
              value = value.replace(/\D/g, "");
              setValue("password", value);
              if (value.length === 4) {
                if (isBusinessCheck) {
                  setFocus("businessNumber");
                }
                setFocus("registrationNumber");
              }
            }}
          />

          {isBusinessCheck ? (
            <EffectiveInput
              name="businessNumber"
              register={register("businessNumber", {
                required: "BUSINESS ERROR",
              })}
              placeholder="사업자번호 10자리"
              value={watch("businessNumber")}
              setFocus={setFocus}
              maxLength={12}
              numberKeyPad
              inputMode="numeric"
              autoComplete={"off"}
              onChange={(text) => {
                businessNumberChange(text);
              }}
            />
          ) : (
            <EffectiveInput
              name="registrationNumber"
              register={register("registrationNumber", {
                required: "REGISTRATION ERROR",
              })}
              placeholder="주민번호 앞 6자리"
              value={watch("registrationNumber")}
              setFocus={setFocus}
              maxLength={6}
              numberKeyPad
              inputMode="numeric"
              autoComplete={"off"}
              onChange={(text) => {
                const value = text.target.value;
                setValue("registrationNumber", value);
              }}
            />
          )}
        </InputWrap>
      </PaymentWrap>

      <BottomButton
        buttonText={strings.ButtonText.Paying}
        pointerDisabled={true}
        onClick={onPayButtonPress}
        disabled={!isPayInfoCheck()}
        buttonRef={buttonRef}
      />
    </Layout>
  );
};

export default Payment;

const PaymentWrap = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-top: 22px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 600px;
`;

const TextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
  color: ${colors.GRAY6};
`;

const StyledDiv = styled.div`
  ${typo.BODY_7}
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const Text = styled.p`
  ${typo.BODY_9}
`;

const StyledSpan = styled.span<{ isActive: boolean }>`
  width: 24px;
  height: 24px;
  background-color: ${({ isActive }) =>
    isActive ? colors.PRIMARY : colors.GRAY8};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  transition: all 0.3s ease;
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PaymentInputWrap = styled.div<{ isFocus: boolean }>`
  /*  */

  padding: ${({ isFocus }) => (isFocus ? "12px 20px" : "20px")};
  border: 1px solid ${colors.GRAY9};
  border-radius: 12px;
`;

const PaymentInputPlaceHolder = styled.p<{ state: boolean }>`
  /*  */
  transition: all 0.3s ease;
  ${({ state }) => {
    if (state) {
      return css`
        ${typo.BODY_11}
      `;
    } else {
      return css`
        ${typo.BODY_4}
      `;
    }
  }}

  color: ${colors.GRAY6};
`;

export const StyledInput = styled.input<{ isActive: boolean }>`
  width: auto;
  /* max-width: 55px; */
  padding: 0;
  ${typo.BODY_4};
  opacity: ${({ isActive }) => (isActive ? "1" : "0")};
  border: none;
  outline: none;
  font-weight: 500;
  line-height: 26px;
  color: ${colors.GRAY2};
  background-color: transparent;
  transition: all 0.6s ease;
`;

const CardInputWrap = styled.div<{ state: boolean }>`
  /*  */
  display: flex;
  transition: all 0.6s ease;

  ${({ state }) => {
    if (!state) {
      return css`
        max-height: 1px;
      `;
    }
  }}
`;
