export const ChargingPrecautionsData = [
  {
    content:
      "충전 현황은 2분마다 업데이트 되며, 통신 상황 등에 따라 실제 금액과 다를 수 있어요.",
    key: 1,
  },
  // {
  //   content:
  //     "실제 금액은 실시간 충전금액을 의미하며, 충전상태는 2분 단위로 갱신돼요.",
  //   key: 2,
  // },
];
