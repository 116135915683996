import { ChargerStateResponse, CheckChargerResponse } from "@apis/types";
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

interface UseChargerInfoStore
  extends Partial<Pick<ChargerStateResponse, "chargingStartAt">> {
  chargerInfo: Partial<CheckChargerResponse> | null;
}

const useChargerInfoStore = atom<UseChargerInfoStore>({
  key: "useChargerInfoStore",
  default: {
    chargerInfo: null,
  },
  effects_UNSTABLE: [persistAtom],
});

export default useChargerInfoStore;
