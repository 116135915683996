import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import styled from "styled-components";

interface LottieProps {
  lottieName: string;
  width?: number;
  height?: number;
}

/**
 * @param lottieName string => lottie의 json 파일명
 * @param width number
 * @param height number
 */
const Lottie: React.FC<LottieProps> = ({ lottieName, width, height }) => {
  const lottieContainer = useRef(null);

  useEffect(() => {
    const Lottie = lottie.loadAnimation({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      container: lottieContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: `/lottieJson/${lottieName}.json`, // the path to the animation json
    });

    return () => Lottie.destroy();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <LottieContainer width={width} height={height} ref={lottieContainer} />
  );
};

export default Lottie;

const LottieContainer = styled.div<{
  width?: number;
  height?: number;
}>`
  /*  */
  width: ${({ width }) => (width ? width : "100%")}px;
  height: ${({ height }) => (height ? height : "100%")}px;
`;
