import GlobalModal from "@components/molecules/Modal/GlobalModal";
import GlobalPopup from "@components/molecules/Popup/GlobalPopup";
import urls from "@constants/urls";
import useGlobalModal from "@hooks/useGlobalModal";
import useRouter from "@hooks/useRouter";
import Charging from "@pages/Charging";
import ChargingCompleted from "@pages/ChargingCompleted";
import CheckInformationCharging from "@pages/CheckInformationCharging";
import Connecting from "@pages/Connecting";
import LandingPage from "@pages/Landing";
import Payment from "@pages/Payment";
import useChargerInfoStore from "@recoil/useChargerInfoStore";
import userStatusType from "@recoil/userStatusType";
import { GlobalStyle } from "@styles/globalStyle";
import DeviceTypeCheck from "@utils/deviceTypeCheck";
import isRandomBoolean from "@utils/isRandom";
import openEveronApp from "@utils/openEveronApp";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { requestCheckCharger } from "./apis";

/* TODO
 * lending page popup background(검정배경) height값 체크 // FIXME
 *
 * //  qrCode 생성 url http://192.168.0.8:3000/?simpleCode=000002
 * //  qrCode 생성 url http://192.168.0.77:3000/?simpleCode=000002
 *
 * // TODO qrCode 생성 url http://192.168.0.77:3000/v1/landing?qrcode=000002
 *
 * // TODO 현재 에버온 QRcode https://qr.everon.co.kr/v1/landing?qrcode=EV000157
 *
 * https://qr.everon.co.kr/v1/landing?qrcode=EV000157
 *
 * 처음 들어왔을시, 들어온 날짜를 useData에 저장 해 놓고,
 * 하루가 지나면, isFirstVisit 의 값은 falsey
 *
 */

const Wrapper = ({ children }: { children: React.ReactElement }) => {
  const { hideModal } = useGlobalModal();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    hideModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return children;
};

function App() {
  const [userStatus, setUserStatus] = useRecoilState(userStatusType);
  const { transactionId } = userStatus;
  const { replace } = useRouter();
  const [isChargerInfo, setChargerInfo] = useRecoilState(useChargerInfoStore);
  const { chargerInfo } = isChargerInfo;
  const { showAlert, hideModal } = useGlobalModal();
  const [simpleCode, setSimpleCode] = useState<string>("");

  /** 충전 중인지 체크  */
  useEffect(() => {
    if (transactionId) {
      replace(urls.Charging);
    }
  }, []);

  /**
   * url 파라미터 확인
   */
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const simpleCode =
      urlParams.get("qrcode" || "qrCode")?.replace("EV", "") ||
      chargerInfo?.simpleCode;

    if (!simpleCode) {
      showAlert({
        title: "사용가능한 상태가 아닌 충전기에요",
        message: "다른 충전기를 이용해주세요",
      });
    } else {
      setSimpleCode(simpleCode);
    }
  }, []);

  /**
   * 충전기 조회
   */
  useQuery(
    ["checkCharger", simpleCode],
    () => requestCheckCharger(simpleCode),
    {
      onSuccess: (data) => {
        const chargerStatus = data?.status;
        if (chargerStatus !== "canUse") {
          showAlert({
            title: "사용가능한 상태가 아닌 충전기에요",
            message: "다른 충전기를 이용해주세요",
            handleConfirm: () => {
              // window.close();
              hideModal();
            },
            handleClose: () => {
              // window.close();
              hideModal();
            },
          });
        } else {
          setChargerInfo({ chargerInfo: data });
          openEveronApp(simpleCode, true);
        }
      },
      onError: (ERROR) => {
        showAlert({
          title: "사용가능한 상태가 아닌 충전기에요",
          message: "다른 충전기를 이용해주세요",
          handleConfirm: () => {
            // window.close();
            hideModal();
          },
          handleClose: () => {
            // window.close();
            hideModal();
          },
        });
      },
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!simpleCode,
    },
  );

  /** 디바이스 체크 */
  useEffect(() => {
    if (DeviceTypeCheck() === "iphone") {
      setUserStatus({ ...userStatus, deviceType: "iphone" });
    } else {
      setUserStatus({ ...userStatus, deviceType: "android" });
    }
  }, []);

  return (
    <>
      <GlobalStyle />

      <Wrapper>
        <Routes>
          <Route
            path={urls.LandingPage}
            element={<LandingPage isRandomBoolean={isRandomBoolean()} />}
          />
          <Route
            path={urls.CheckInformationCharging}
            element={<CheckInformationCharging />}
          />
          <Route path={urls.Payment} element={<Payment />} />
          <Route path={urls.Connecting} element={<Connecting />} />
          <Route path={urls.Charging} element={<Charging />} />
          <Route
            path={urls.ChargingCompleted}
            element={<ChargingCompleted />}
          />
          <Route
            path="*"
            element={<Navigate replace to={urls.LandingPage} />}
          />
        </Routes>
      </Wrapper>
      <GlobalModal />
      <GlobalPopup />
    </>
  );
}

export default App;
