import { requestChargerState } from "@apis/index";
import { ChargerStateResponse } from "@apis/types";
import Lottie from "@components/atoms/Lottie";
import StopWatch from "@components/atoms/StopWatch";
import BottomButton from "@components/molecules/BottomButton";
import Precautions from "@components/molecules/Precautions";
import Layout from "@components/organisms/Layout";
import strings from "@constants/strings";
import urls from "@constants/urls";
import useGlobalModal from "@hooks/useGlobalModal";
import useGlobalPopup from "@hooks/useGlobalPopup";
import useRouter from "@hooks/useRouter";
import { ChargingPrecautionsData } from "@mock/Charging";
import { ErrorResponseType } from "@network/types";
import useChargerInfoStore from "@recoil/useChargerInfoStore";
import userStatusType from "@recoil/userStatusType";
import { colors } from "@styles/index";
import React from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import {
  Bar,
  ChargingAmount,
  ChargingAmountTitle,
  ChargingAmountWrap,
  ChargingInformationWrap,
  ChargingText,
  ChargingUnit,
  ContentsWrap,
  ContentWrap,
  InformationContent,
  InformationTitle,
  LottieContentsWrap,
  LottieWrap,
  SubTitle,
  Title,
  TitleWrap,
} from "./styles";

/* TODO
 *
 * // FIXME 디바이스 타입체크 해서 인터렉션 나눠주기
 * // [ ] popup 올라오는거 체크
 */

const Charging: React.FC = () => {
  const { replace } = useRouter();
  const { showPopup } = useGlobalPopup();
  const { hideModal } = useGlobalModal();
  const [isChargerInfo, setChargerInfo] = useRecoilState(useChargerInfoStore);
  const [userStatus, setUserStatus] = useRecoilState(userStatusType);
  const { chargerInfo } = isChargerInfo;
  const { transactionId } = userStatus;

  /**
   * 비회원 충전 상태 조회
   */
  const { data } = useQuery(
    ["chargerState", transactionId],
    () => requestChargerState(transactionId),
    {
      onSuccess: (data) => {
        setChargerInfo({
          chargerInfo: data.chargerInfo,
          chargingStartAt: data.chargingStartAt,
        });
      },
      onError: (error: ErrorResponseType) => {
        /** // FIXME 현재 netWork Error로 데이터 조회 안되서 계속 404 나오는중 */
        // 404 에러일 경우 ( 비원격 충전 종료 )
        if (error.statusCode === 404) {
          if (!chargerInfo) return;
          setChargerInfo({
            chargerInfo: { simpleCode: chargerInfo?.simpleCode },
          });
          setUserStatus({
            ...userStatus,
            socialNumber: "",
            transactionId: "",
          });
          hideModal();
          replace(urls.LandingPage);
        }
      },
      refetchInterval: 120000,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  // 충전종료 팝업 오픈
  const handleClickChargingEnd = () => {
    window.scrollTo(0, 0);
    showPopup({
      popupType: "ChargingEndPopup",
      popupProps: {
        isPopupActive: false,
      },
    });
  };

  const chargerList = [
    {
      key: 1,
      title: "충전소명",
      content: chargerInfo?.stationName,
    },
    {
      key: 2,
      title: "충전소 ID",
      content: chargerInfo?.simpleCode,
    },
    {
      key: 3,
      title: "충전소 유형",
      content: `${chargerInfo?.chargerTypeDescription}(${chargerInfo?.chargerCapacity}kW)`,
    },
    {
      key: 4,
      title: "현재 충전량",
      content: `${data?.chargingAmount ?? "0"}kWh`,
    },
  ];

  return (
    <Layout disabledMainLayoutPadding>
      <ContentsWrap>
        <TitleWrap>
          <Title>
            {chargerInfo?.chargerSpeedType === "rapid" ? "급속 " : "완속 "}{" "}
            충전중이에요
          </Title>
          <SubTitle>충전중에는 커넥터를 강제로 분리하지 마세요.</SubTitle>
        </TitleWrap>

        <LottieWrap>
          <Lottie lottieName="충전중" />
          <LottieContentsWrap>
            <LottieSmall>
              <Lottie lottieName="충전중 번개" />
            </LottieSmall>
            <ChargingText>
              {data?.chargingAmount ?? "0"}
              <ChargingUnit>kWh</ChargingUnit>
            </ChargingText>

            <StopWatch />
          </LottieContentsWrap>
        </LottieWrap>

        <ChargingInformationWrap>
          {chargerList.map((value) => {
            return (
              <ContentWrap key={value.key}>
                <InformationTitle>{value.title}</InformationTitle>
                <InformationContent>{value.content}</InformationContent>
              </ContentWrap>
            );
          })}

          <Bar />
          <ChargingAmountWrap>
            <ChargingAmountTitle>충전 예상 금액</ChargingAmountTitle>
            <ChargingAmount>
              {data?.chargingFee.toLocaleString("ko-KR") ?? "0"}원
            </ChargingAmount>
          </ChargingAmountWrap>
        </ChargingInformationWrap>
      </ContentsWrap>

      {/* TODO 분기처리 유의사항 사라지고 버튼만 있게 (충전기에서 종료 버튼을 눌러주세요) */}
      <Precautions
        data={ChargingPrecautionsData}
        background={`${colors.GRAY11}`}
        heightExclude={891}
      />
      {chargerInfo?.isRemoteStop ? (
        <BottomButton
          buttonColor="mainBlue"
          buttonText={strings.ButtonText.ChargingEnd}
          onClick={handleClickChargingEnd}
        />
      ) : (
        <BottomButton
          type="oneButton"
          buttonType="line"
          buttonColor="gray"
          buttonText={strings.ButtonText.PressChargingEndButton}
          pointerDisabled
        />
      )}
    </Layout>
  );
};

export default Charging;

const LottieSmall = styled.div`
  /*  */
  position: absolute;
  top: 60px;
  width: 40px;
  height: 40px;
`;
