import { useBodyScrollLock } from "@hooks/useBodyScrollLock";
import globalPopupState from "@recoil/globalPopup";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import ChargingEndPopup from "../ChargingEndPopup";
import ChargingNonMemberPopUp from "../ChargingNonMemberPopUp";
import TermsPopup from "../TermsPopup";

const POPUP_TYPES = {
  ChargingNonMemberPopup: "ChargingNonMemberPopup",
  ChargingEndPopup: "ChargingEndPopup",
  TermsPopup: "TermsPopup",
} as const;

const POPUP_COMPONENTS: any = {
  [POPUP_TYPES.ChargingNonMemberPopup]: ChargingNonMemberPopUp,
  [POPUP_TYPES.ChargingEndPopup]: ChargingEndPopup,
  [POPUP_TYPES.TermsPopup]: TermsPopup,
};

function GlobalPopup() {
  const { popupProps, popupType } = useRecoilValue(globalPopupState);
  const { lockScroll, openScroll } = useBodyScrollLock();

  useEffect(() => {
    // FIXME lock에 또 lock을 걸면 어떻게 될지는 모르겟다..?
    if (popupType) {
      lockScroll();
    } else {
      openScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupType]);

  const renderComponent = () => {
    if (!popupType) {
      return null;
    }
    const PopupComponent = POPUP_COMPONENTS[popupType];
    return <PopupComponent {...popupProps} />;
  };

  return <>{renderComponent()}</>;
}

export default GlobalPopup;
