import { ICONS } from "@assets/index";
import { Icon } from "@components/atoms/Button/IconButton";
import BottomButton from "@components/molecules/BottomButton";
import { StyledBar } from "@components/molecules/Popup/ChargingNonMemberPopUp";
import Precautions from "@components/molecules/Precautions";
import Layout from "@components/organisms/Layout";
import strings from "@constants/strings";
import urls from "@constants/urls";
import useRouter from "@hooks/useRouter";
import { PrecautionsData } from "@mock/CheckInformationPage";
import useChargerInfoStore from "@recoil/useChargerInfoStore";
import React from "react";
import { useRecoilValue } from "recoil";
import {
  ChargerInformation,
  ChargerInformationTitle,
  ChargerInformationWrap,
  Contents,
  PaymentAmount,
  PaymentAmountWrap,
  PaymentWrap,
  StyledDiv,
  StyledNoti,
  StyledNotiText,
  SubTitle,
} from "./styles";

const CheckInformationCharging: React.FC = () => {
  const { replace } = useRouter();
  const { chargerInfo } = useRecoilValue(useChargerInfoStore);

  const ChargerInformationData = [
    {
      title: "충전소명",
      content: chargerInfo?.stationName,
    },
    {
      title: "주소",
      content: chargerInfo?.address,
    },
    {
      title: "상세주소",
      content: chargerInfo?.stationLocation,
    },
    {
      title: "충전기ID",
      content: chargerInfo?.simpleCode,
    },
    {
      title: "충전기 유형",
      content: `${chargerInfo?.chargerTypeDescription}(${chargerInfo?.chargerCapacity}kW)`,
    },
    {
      title: "충전단가",
      content: `${chargerInfo?.guestUnitFee}원/kWh`,
    },
  ];

  return (
    <Layout
      title={strings.Title.ChargingNonMember}
      close
      disabledMainLayoutPadding
      onCloseClick={() => replace(urls.LandingPage)}
    >
      <StyledDiv>
        <PaymentWrap>
          <PaymentAmountWrap>
            선결제 금액
            <PaymentAmount>30,000원</PaymentAmount>
          </PaymentAmountWrap>

          <StyledNoti>
            <Icon icon={ICONS.light_bulb} width={20} height={20} />
            <StyledNotiText>
              충전금액을 뺀 차액은 카드사 정책에 따라 3~5일 이내에 부분취소
              예정이에요.
            </StyledNotiText>
          </StyledNoti>
        </PaymentWrap>
        <StyledBar />
        <ChargerInformationWrap>
          <ChargerInformationTitle>충전기 정보</ChargerInformationTitle>

          {ChargerInformationData.map(({ title, content }) => {
            if (!content) return;
            return (
              <ChargerInformation key={title}>
                <SubTitle>{title}</SubTitle>
                <Contents>{content}</Contents>
              </ChargerInformation>
            );
          })}
        </ChargerInformationWrap>
      </StyledDiv>

      {/* 유의사항 */}
      <Precautions
        title={strings.SubTitle.Precautions}
        data={PrecautionsData}
        heightExclude={756}
      />
      <BottomButton
        buttonText={strings.ButtonText.Paying}
        buttonColor="mainBlue"
        buttonSize="extra"
        // onClick={() => push(urls.Connecting)}
        onClick={() => replace(urls.Payment)}
      />
    </Layout>
  );
};

export default CheckInformationCharging;
