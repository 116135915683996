import { ChargingEndPopupProps } from "@components/molecules/Popup/ChargingEndPopup";
import { ChargingNonMemberPopupProps } from "@components/molecules/Popup/ChargingNonMemberPopUp";
import { TermsPopupProps } from "@components/molecules/Popup/TermsPopup";

import { atom } from "recoil";

export interface GlobalPopupType {
  popupType:
    | "ChargingEndPopup"
    | "ChargingNonMemberPopup"
    | "TermsPopup"
    | null;
  popupProps:
    | ChargingEndPopupProps
    | ChargingNonMemberPopupProps
    | TermsPopupProps
    | null;
}

const globalPopupState = atom<GlobalPopupType>({
  key: "globalPopup",
  default: {
    popupType: null,
    popupProps: null,
  },
});

export default globalPopupState;
