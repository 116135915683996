import { PaymentsTermsResponse } from "@apis/types";
import { ICONS } from "@assets/index";
import {
  Icon,
  Icon as TermsCheckIcon,
} from "@components/atoms/Button/IconButton";
import { colors, typo } from "@styles/index";
import React from "react";
import styled from "styled-components";

interface TermsItemProps
  extends Pick<PaymentsTermsResponse, "detail" | "title" | "isEssential"> {
  subTitle?: string;
  onClick: (detail: string) => void;
  agreeTerms: string[];
  setAgreeTerms: React.Dispatch<React.SetStateAction<string[]>>;
}

const TermsItem: React.FC<TermsItemProps> = ({
  detail,
  title,
  isEssential,
  onClick,
  subTitle,
  agreeTerms,
  setAgreeTerms,
}) => {
  // 약관 동의 여부 체크
  const isAgreedCheck = (title: string) => {
    return agreeTerms.includes(title);
  };

  // 약관 동의
  const isAgreed = (title: string) => {
    if (isAgreedCheck(title)) {
      setAgreeTerms((prev) => prev.filter((term) => term !== title));
    } else {
      setAgreeTerms((prev) => [...prev, title]);
    }
  };

  return (
    <TermsWrap>
      <TermsCheckIcon
        icon={
          isAgreedCheck(title)
            ? ICONS.Controls.check_selected
            : ICONS.Controls.check_default
        }
        width={24}
        height={24}
        onClick={() => isAgreed(title)}
      />

      <TermsContentWrap>
        {/* TODO */}
        {/* width값에 따라서 font size 변경이 필요해 보임. 320px일 경우 폰트 사이크 큼 */}
        <TermsContent onClick={() => onClick(detail)}>
          <TermsPoint>{isEssential ? "[필수]" : "[선텍]"}</TermsPoint> {title}
        </TermsContent>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        <TermsDetailIcon>
          <Icon
            icon={ICONS.right_arrow}
            width={16}
            height={16}
            disabledIconCover
          />
        </TermsDetailIcon>
      </TermsContentWrap>
    </TermsWrap>
  );
};

export default TermsItem;

const TermsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  & + & {
    margin-top: 16px;
  }
`;

const TermsContentWrap = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`;

const TermsContent = styled.p`
  ${typo.BODY_6}
`;

const TermsPoint = styled.span`
  font-weight: 500;
`;

const SubTitle = styled.p`
  /*  */
  color: ${colors.GRAY6};
  ${typo.BODY_10};
  margin-top: 4px;
`;

const TermsDetailIcon = styled.span`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
`;
