import { ICONS } from "@assets/index";
import { Icon } from "@components/atoms/Button/IconButton";
import useRouter from "@hooks/useRouter";
import globalModalState from "@recoil/globalModal";
import globalPopupState from "@recoil/globalPopup";
import { typo } from "@styles/index";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styled, { css } from "styled-components";

type ButtonType = "back" | "close";

interface HeaderProps {
  title?: string;
  back?: boolean;
  close?: boolean;
  onCloseClick?: () => void;
  onBackClick?: () => void;
}

const Header: React.FC<HeaderProps> = ({
  title,
  back,
  close,
  onCloseClick,
  onBackClick,
}) => {
  /* TODO
   * back 기능
   * close 기능
   */
  const { goBack } = useRouter();
  const { modalType } = useRecoilValue(globalModalState);
  const { popupType } = useRecoilValue(globalPopupState);
  const [isModalOrPopupActive, setIsModalOrPopupActive] = useState(false);

  useEffect(() => {
    if (modalType || popupType) {
      setIsModalOrPopupActive(true);
    } else {
      setIsModalOrPopupActive(false);
    }
  }, [modalType, popupType]);

  return (
    <StyledHeader isModalOrPopupActive={isModalOrPopupActive}>
      {back && (
        <ButtonWrap
          ButtonType={"back"}
          onClick={() => {
            if (onBackClick) {
              onBackClick();
            } else {
              goBack();
            }
          }}
        >
          <Icon icon={ICONS.left_arrow} width={10} height={19} />
        </ButtonWrap>
      )}

      {title && <StyledH1>{title}</StyledH1>}

      {close && (
        <ButtonWrap ButtonType={"close"} onClick={onCloseClick}>
          <Icon icon={ICONS.header_close} width={16} height={16} />
        </ButtonWrap>
      )}
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled.header<{
  isModalOrPopupActive: boolean;
}>`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 60px;
  background-color: white;
  padding: 0 16px;
  z-index: ${({ isModalOrPopupActive }) => (isModalOrPopupActive ? "0" : "1")};
`;

const StyledH1 = styled.h1`
  ${typo.HEADING_6}
  margin: auto;
`;

const ButtonWrap = styled.div<{ ButtonType?: ButtonType }>`
  padding: ${({ ButtonType }) =>
    ButtonType === "back" ? "4px 10px 4px 9px" : "7px"};

  cursor: pointer;
  position: absolute;

  ${({ ButtonType }) => {
    if (ButtonType === "back") {
      return css`
        left: 16px;
      `;
    } else {
      return css`
        right: 16px;
      `;
    }
  }}
`;
