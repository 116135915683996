import { ICONS } from "@assets/index";
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonTypes,
} from "@components/atoms/Button";
import TextButton, {
  TextButtonColor,
} from "@components/atoms/Button/TextButton";
import React from "react";
import styled from "styled-components";
import { Icon } from "@components/atoms/Button/IconButton";

interface BottomButtonProps {
  type?: "twoButtons" | "oneButton";
  buttonSize?: ButtonSize;
  buttonText?: string;
  buttonType?: ButtonTypes;
  secondButtonText?: string;
  buttonColor?: ButtonColor;
  secondButtonColor?: TextButtonColor;
  onClick?: () => void;
  secondButtonOnClick?: () => void;
  pointerDisabled?: boolean;
  disabled?: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  twoButtonIcon?: string;
}

const BottomButton: React.FC<BottomButtonProps> = ({
  type = "oneButton",
  buttonText,
  secondButtonText,
  buttonColor,
  secondButtonColor,
  buttonSize,
  buttonType,
  onClick,
  secondButtonOnClick,
  pointerDisabled,
  disabled,
  buttonRef,
  twoButtonIcon,
}) => {
  if (type === "oneButton") {
    return (
      <BottomWrap>
        <Gradient />
        <ButtonWrap>
          <Button
            full
            btnType={buttonType}
            size={buttonSize}
            color={buttonColor}
            text={buttonText}
            onClick={onClick}
            pointerDisabled={pointerDisabled}
            disabled={disabled}
            buttonRef={buttonRef}
          />
        </ButtonWrap>
      </BottomWrap>
    );
  }

  if (type === "twoButtons") {
    return (
      <BottomWrap>
        <Gradient />
        <ButtonWrap>
          <Button
            full
            size={buttonSize}
            color={buttonColor}
            text={buttonText}
            onClick={onClick}
          />
          <div
            style={{
              display: "flex",
            }}
            onClick={secondButtonOnClick}
          >
            <div
              style={{
                width: "auto",
                marginRight: "8px",
              }}
            >
              {twoButtonIcon && (
                <Icon icon={twoButtonIcon} width={62} height={32} />
              )}
            </div>
            <TextButton
              color={secondButtonColor}
              text={secondButtonText}
              onClick={secondButtonOnClick}
            />
          </div>
        </ButtonWrap>
      </BottomWrap>
    );
  }

  return <></>;
};

export default BottomButton;

export const BottomWrap = styled.div`
  /*  */
  width: 100%;
  min-width: 20rem;
  max-width: 45rem;

  /* position: absolute; */
  /* position: sticky; // 그나마 두번째... */
  position: fixed; // 이게 모바일에서는 젤 나아 보이는데...
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const Gradient = styled.div`
  height: 32px;
  background: gray;
  background: linear-gradient(360deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
`;

export const ButtonWrap = styled.div`
  padding: 0 20px 20px;
  background: white;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;
