import useChargerInfoStore from "@recoil/useChargerInfoStore";
import { colors, typo } from "@styles/index";
import React from "react";
import { useStopwatch } from "react-timer-hook";
import { useRecoilState } from "recoil";
import styled from "styled-components";

const StopWatch: React.FC = () => {
  const [{ chargingStartAt }, _] = useRecoilState(useChargerInfoStore);

  const startAt = chargingStartAt ? new Date(chargingStartAt).getTime() : 0;
  const toDay = new Date().getTime();

  const Init = (toDay - startAt) / 1000;

  const stopwatchOffset = new Date();
  stopwatchOffset.setSeconds(
    stopwatchOffset.getSeconds() + (chargingStartAt ? Init : 0),
  );

  const { minutes, hours } = useStopwatch({
    autoStart: true,
    offsetTimestamp: stopwatchOffset,
  });

  return (
    <TimerText>
      {hours}시간 {minutes}분 경과
    </TimerText>
  );
};

export default StopWatch;

const TimerText = styled.small`
  /*  */
  ${typo.BODY_9}
  color: ${colors.GRAY5};
`;
