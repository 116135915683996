import { colors, typo } from "@styles/index";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import styled, { css } from "styled-components";

/* TODO
 * 유효성 통과되면 다음로 focus
 * value 다 지우고, blur 하면 label 커지게
 */

type InputType = "text" | "password" | "number";

interface EffectiveInputProps {
  type?: InputType;
  placeholder?: string;
  value?: string;
  maxLength?: number;
  register?: UseFormRegisterReturn;
  name?: any; // FIXME type 지정 해주기
  setFocus?: any;
  numberKeyPad?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;

  [key: string]: any;
}

/**
 * @param type "text" | "password"
 * @param placeholder string
 * @param maxLength number
 * @param value hook form의 watch 이용 ex) watch("input_name")
 * @param register hook form
 * @param name hook form에 input 지정해놓은 name
 * @param setFocus hook form의 setFocus
 */
const EffectiveInput: React.FC<EffectiveInputProps> = ({
  type = "text",
  placeholder,
  value,
  register,
  maxLength,
  setFocus,
  name,
  numberKeyPad,
  onChange,
  inputRef,
  ...res
}) => {
  // placeholder active state
  const [state, setState] = useState(false);

  // const inputRef = useRef<HTMLInputElement>(null);
  // FIXME
  const onFocusInput = () => {
    if (value) {
      return null;
    }
    if (state) {
      setState(false);
    }

    setFocus(name);
    setState(true);
  };

  return (
    <StyledWrap
      state={state}
      onBlur={() => {
        if (value) {
          return null;
        }
        setState(!state);
      }}
      onFocus={onFocusInput}
    >
      <Placeholder state={state} onClick={onFocusInput}>
        {placeholder}
      </Placeholder>

      {numberKeyPad ? (
        <StyledInput
          {...register}
          type={type}
          maxLength={maxLength}
          state={state}
          onChange={onChange}
          pattern="\d*"
          {...res}
        />
      ) : (
        <StyledInput
          {...register}
          type={type}
          maxLength={maxLength}
          state={state}
          onChange={onChange}
          {...res}
        />
      )}
    </StyledWrap>
  );
};

export default EffectiveInput;

const StyledWrap = styled.div<{ state: boolean }>`
  /*  */
  width: 100%;
  height: 70px;
  border-radius: 12px;
  border: 1px solid ${colors.GRAY9};
  padding: 20px;

  transition: all 0.3s ease;
  padding: ${({ state }) => (state ? "12px 20px 20px 20px" : "20px")};

  background: white;
`;

const Placeholder = styled.p<{ state: boolean }>`
  /*  */
  transition: all 0.3s ease;
  ${({ state }) => {
    if (state) {
      return css`
        ${typo.BODY_11}
      `;
    } else {
      return css`
        ${typo.BODY_4}
      `;
    }
  }}

  color: ${colors.GRAY6};
`;

const StyledInput = styled.input<{ state: boolean }>`
  /*  */
  width: 100%;
  /* opacity: ${({ state }) => (state ? "1" : "0")}; */
  border: none;
  outline: none;
  padding: 0;
  ${typo.BODY_4}
  font-weight: 500;
  line-height: 26px;
  color: ${colors.GRAY2};
  background-color: transparent;
  transition: all 0.6s ease;
  :focus {
    background: none;
  }
  :active {
    background: none;
  }
`;
