import { requestChargerStop } from "@apis/index";
import { CheckChargerResponse } from "@apis/types";
import Button from "@components/atoms/Button";
import EffectiveCardInput, {
  Column,
} from "@components/atoms/Input/EffectiveCardInput";
import { PrecautionsText } from "@components/molecules/Precautions";
import strings from "@constants/strings";
import urls from "@constants/urls";
import useGlobalModal from "@hooks/useGlobalModal";
import useGlobalPopup from "@hooks/useGlobalPopup";
import useRouter from "@hooks/useRouter";
import { StyledInput } from "@pages/Payment";
import useChargerInfoStore from "@recoil/useChargerInfoStore";
import userStatusType, { DeviceType } from "@recoil/userStatusType";
import formatNumber from "@utils/numberFormat";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useRecoilState } from "recoil";
import styled, { css } from "styled-components";
import BasePopup from "../BasePopup";

export interface ChargingEndPopupProps {}

export interface PaymentInputType {
  socialNumber: string;
}

export interface ViewStateType {
  isKeyboard: "on" | "off";
  currentViewHeight: number;
  defaultViewHeight: number;
}

function ChargingEndPopup({}: ChargingEndPopupProps) {
  const { replace } = useRouter();
  const { showAlert, hideModal, showConfirmModal } = useGlobalModal();
  const { hidePopup } = useGlobalPopup();
  const [userStatus, setUserStatusType] = useRecoilState(userStatusType);
  const { deviceType, socialNumber, transactionId } = userStatus;
  const [{ chargerInfo }, setChargerInfo] = useRecoilState(useChargerInfoStore);

  const { register, setFocus, handleSubmit, setValue, getValues } =
    useForm<PaymentInputType>({
      mode: "onChange",
    });

  const [isPlaceholderActive, setPlaceholderActive] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isViewState, setIsViewState] = useState<ViewStateType>({
    isKeyboard: "off",
    currentViewHeight: 0,
    defaultViewHeight: window.innerHeight,
  });
  const [inputCount, setInputCount] = useState<number>(0); // FIXME input 클릭 횟수 || naming 바꿔야 될듯.

  /** 주빈번호인지, 사업자인지 체크  */
  const isBusinessCheck = socialNumber?.length === 6;

  /** 키보드 활성화 감지 */
  const BottomHeight =
    isViewState.currentViewHeight === 0
      ? 0
      : isViewState.defaultViewHeight - isViewState.currentViewHeight;

  const { mutateAsync } = useMutation(requestChargerStop, {
    onSuccess: () => {
      endFlow();
    },
    onError: (error) => {
      console.log("📘 >>> CHARGER STOP", error);
      showAlert({
        title: strings.Modal.Title.ChargingEndNetworkError,
        message: strings.Modal.Message.ChargingEndNetworkError,
        handleConfirm: () => {
          endFlow();
        },
        handleClose: () => {
          endFlow();
        },
      });
    },
  });

  const endFlow = () => {
    if (!chargerInfo) return;
    hideModal();
    setChargerInfo({
      chargerInfo: { simpleCode: chargerInfo?.simpleCode },
    });
    setUserStatusType({
      ...userStatus,
      socialNumber: "",
      transactionId: "",
    });
    replace(urls.LandingPage);
  };

  /** input focus시 작동 */
  const onClickPlaceholder = () => {
    window.scrollTo(0, 0);
    setPlaceholderActive(true);

    if (deviceType === "iphone") {
      setTimeout(() => {
        setFocus("socialNumber");
      }, 1000);
    }
    setFocus("socialNumber");
  };

  /** 키보드 올라왔을때 viewProt 측정 */
  const reSizeViewHeight = () => {
    window.visualViewport!.onresize = () => {
      setIsViewState((prev) => {
        return {
          ...prev,
          currentViewHeight:
            window.visualViewport!.height === 0
              ? 0
              : window.visualViewport!.height,
        };
      });
    };
  };

  /** 충전 종료 */
  const handleSubmitSocialNumber = (text: PaymentInputType) => {
    const checkValue = text.socialNumber.replace(/ /g, "");
    // console.log("🧨", socialNumber);
    // console.log("🔱", checkValue);

    const { chargerId, chargerInterfaceType, connectorId, stationId } =
      chargerInfo as CheckChargerResponse;

    // FIXME 예외처리
    if (!chargerInfo) return;
    if (!transactionId) return;

    if (checkValue === socialNumber) {
      closePopup();

      console.log("📕 transactionId", transactionId);

      showConfirmModal({
        title: strings.Modal.Title.StopCharging,
        onClickFirstButton: () => hideModal(),
        onClickSecondButton: () => {
          mutateAsync({
            chargerId,
            socialNumber: checkValue,
            transactionId,
            chargerInterfaceType,
            connectorId,
            stationId,
          });
          hideModal();
        },
      });
      //
    } else {
      // 생년월일 & 사업자번호 틀렸을때 modal
      showAlert({
        title: strings.Modal.Title.EnteredWrongDate,
        message: strings.Modal.Message.EnteredWrongDate,
        handleConfirm: () => hideModal(),
      });
    }
  };

  // input Change
  const inputOnChange = (text: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = text.currentTarget;
    if (isBusinessCheck) {
      setValue("socialNumber", value);
    } else {
      const prevValue = value;
      const formattedNumber = formatNumber(value);
      value = formattedNumber;

      if (
        value.length < prevValue.length &&
        (prevValue.length === 4 || prevValue.length === 7)
      ) {
        value = value.trim();
      }
      setValue("socialNumber", value);
    }
  };

  const closePopup = () => {
    setTimeout(() => {
      setIsPopupOpen(false);
      hidePopup();
    }, 300);
    setIsViewState((prev) => {
      return {
        ...prev,
        isKeyboard: "off",
      };
    });
    console.log("작동??");
  };

  useEffect(() => {
    setTimeout(() => {
      setIsPopupOpen(true);
      if (deviceType === "android") {
        setPlaceholderActive(true);
        setFocus("socialNumber");
      }
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceType]);

  useEffect(() => {
    /** 스크롤시 팝업 닫힘 */
    if (BottomHeight > 200 && isViewState.isKeyboard === "on") {
      console.log("wer234");
      window.addEventListener("scroll", closePopup);

      return () => {
        window.removeEventListener("scroll", closePopup);
      };
    }

    // && isPopupOpen
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewState, isPopupOpen]);

  return (
    <BasePopup
      isOpen={isPopupOpen}
      handleClose={closePopup}
      isPopupOpen={isPopupOpen}
    >
      <ChargingEndContentsWrap
        isPopupOpen={isPopupOpen}
        isKeyboard={isViewState.isKeyboard}
        deviceType={deviceType}
        bottomHeight={BottomHeight}
        inputCount={inputCount}
      >
        <StyledDiv>
          <EffectiveCardInput
            placeholder="주민번호 앞자리 / 사업자등록번호"
            isActive={isPlaceholderActive}
            onClick={onClickPlaceholder}
          >
            <PopupColumn>
              <PopupStyledInput
                id="input"
                inputMode={"numeric"}
                isActive={isPlaceholderActive}
                {...register("socialNumber", { required: true })} // FIXME 정규식 넣기
                maxLength={isBusinessCheck ? 6 : 12}
                onFocus={() => {
                  setIsViewState((prev) => {
                    return { ...prev, isKeyboard: "on" };
                  });
                  setInputCount((prev) => prev + 1);
                  reSizeViewHeight();
                }}
                onBlur={() => {
                  window.scrollTo(0, 0);
                  setIsViewState((prev) => {
                    return {
                      ...prev,
                      isKeyboard: "off",
                      currentViewHeight: 0,
                    };
                  });

                  // FIXME 고민 이것도 안먹음...
                  if (deviceType === "android") {
                    if (getValues("socialNumber").length === 0) {
                      setPlaceholderActive(false);
                    }
                  }
                }}
                onChange={(text) => inputOnChange(text)}
                autoComplete={"off"}
              />
            </PopupColumn>
          </EffectiveCardInput>

          <PrecautionsText>
            충전종료를 위해 주민번호 앞 6자리 또는 사업자등록번호 10자리를
            입력해주세요.
          </PrecautionsText>
        </StyledDiv>
        <Button
          full
          style={{ borderRadius: 0, height: 56 }}
          text={strings.ButtonText.ChargingEnd}
          onClick={handleSubmit(handleSubmitSocialNumber)}
        />
      </ChargingEndContentsWrap>
    </BasePopup>
  );
}

export default ChargingEndPopup;

const ChargingEndContentsWrap = styled.div<{
  isPopupOpen?: boolean;
  isKeyboard?: "on" | "off";
  bottomHeight?: number;
  deviceType?: DeviceType;
  inputCount: number;
}>`
  position: fixed;

  ${({ isPopupOpen, isKeyboard, deviceType, bottomHeight, inputCount }) => {
    if (deviceType === "android") {
      return css`
        bottom: ${isPopupOpen ? "0" : "-200px"};
      `;
    }

    if (deviceType === "iphone") {
      if (isPopupOpen && isKeyboard) {
        // FIXME 이 부분 로직 체크 해봐야 됨.
        // FIXME 단축 시킬수 있을꺼 같다.
        return css`
          bottom: ${inputCount === 1 ? bottomHeight : 0}px;
        `;
      } else {
        return css`
          bottom: -200px;
        `;
      }
    }
  }}
  transition: all 0.3s ease;
  min-width: 20rem;
  max-width: 45rem;
  width: 100%;

  height: 218px;
  border-radius: 16px 16px 0 0;

  background: #ffffff;
`;

const StyledDiv = styled.div`
  background: #ffffff;
  height: 162px;
  border-radius: 16px 16px 0 0;
  padding: 24px 20px;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PopupStyledInput = styled(StyledInput)`
  width: 100%;
  padding: 0;
`;

const PopupColumn = styled(Column)`
  width: 100%;
  align-items: center;
  gap: 4px;
`;
