// cSpell:ignore everon
import { CheckChargerResponse } from "@apis/types";
import { ICONS, IMAGES } from "@assets/index";
import { Icon } from "@components/atoms/Button/IconButton";
import { Column } from "@components/atoms/Input/EffectiveCardInput";
import BottomButton from "@components/molecules/BottomButton";
import AppButton from "@components/molecules/LandingPage/AppButton";
import Layout from "@components/organisms/Layout";
import strings from "@constants/strings";
import urls from "@constants/urls";
import useGlobalModal from "@hooks/useGlobalModal";
import useGlobalPopup from "@hooks/useGlobalPopup";
import useRouter from "@hooks/useRouter";
import { ApplicationButtonData } from "@mock/RendingPage";
import useChargerInfoStore from "@recoil/useChargerInfoStore";
import { colors, typo } from "@styles/index";
import openEveronApp from "@utils/openEveronApp";
import redirectApp from "@utils/redirectApp";
import React from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import styled, { css } from "styled-components";

interface LandingPageProps {
  isRandomBoolean: boolean;
}

const LandingPage: React.FC<LandingPageProps> = ({ isRandomBoolean }) => {
  const { replace } = useRouter();
  const isPopupActive = false;
  const { showPopup, hidePopup } = useGlobalPopup();
  const { showAlert } = useGlobalModal();
  const { chargerInfo } = useRecoilValue(useChargerInfoStore);
  const resetChargingInfo = useResetRecoilState(useChargerInfoStore);

  // 다음 페이지
  const onPageMove = () => {
    if (chargerInfo) {
      replace(urls.CheckInformationCharging);
    } else {
      showAlert({
        title: "충전기 정보가 확실하지 않아요.",
        message: "다른 충전기를 사용하시거나\n다시한번 시도해주세요.",
      });
    }

    hidePopup();
  };

  const description = [
    {
      index: 1,
      title: "1. 선결제 하기",
      text: "충전 최대 금액 3만원을 먼저 결재해요.",
      icon: ICONS.cade_landing,
    },
    {
      index: 2,
      title: "2. 충전하기",
      text: "케이블을 연결하면 충전이 시작돼요.",
      icon: ICONS.lightning_landing,
    },
    {
      index: 3,
      title: "3. 돌려받기",
      text: "충전금액을 제외한 금액은 부분취소돼요.",
      icon: ICONS.money_landing,
    },
  ];

  return (
    <>
      <Layout>
        {/* 에버온 충전 서비스 이용 wrap */}
        <MainLogoWrap>
          <p>에버온 회원가입하고</p>
          <p>앱으로 바로 충전하세요!</p>
        </MainLogoWrap>

        {/* 평균 40% wrap */}
        {/* <ContentsWrap height={338} center>
          <StyledDiv>
            <ImageWrap width={259} backgroundSrc={IMAGES.graph} />
          </StyledDiv>
        </ContentsWrap> */}

        {/* 비회원으로 이용 시 선결제하고 충전까지 가능 wrap */}
        <ContentsWrap height={488}>
          {/* <ImageWrap backgroundSrc={IMAGES.charger_detail_info} /> */}
          <div style={{ padding: "60px 0" }}>
            <StyledTitle>{`비회원으로 이용 시\n선결제하고 충전까지 가능`}</StyledTitle>
            <StyledCardContentsWrap>
              {description.map((item) => {
                return (
                  <StyledCard key={item.index}>
                    <Icon icon={item.icon} width={60} height={60} />
                    <StyledCardTextWrap>
                      <p>{item.title}</p>
                      <p>{item.text}</p>
                    </StyledCardTextWrap>
                  </StyledCard>
                );
              })}
            </StyledCardContentsWrap>
          </div>
        </ContentsWrap>

        {/* 제휴 서비스 이용도 가능해요. wrap */}
        <ContentsWrap height={464}>
          <ApplicationWrap>
            <p>제휴 서비스 이용도 가능해요.</p>
            <CustomColumn>
              {(isRandomBoolean
                ? ApplicationButtonData
                : [...ApplicationButtonData].reverse()
              ).map((data) => {
                return (
                  <AppButton
                    data={data}
                    key={data.label}
                    onClick={() => {
                      if (!chargerInfo) {
                        showAlert({
                          title: "충전기 정보가 확실하지 않아요.",
                          message: `다른 충전기를 사용하시거나\n다시한번 시도해주세요.`,
                        });
                      }

                      const { simpleCode } =
                        chargerInfo as CheckChargerResponse;

                      redirectApp(data.label, simpleCode);
                    }}
                  />
                );
              })}
            </CustomColumn>
          </ApplicationWrap>
        </ContentsWrap>

        <BottomButton
          type="twoButtons"
          buttonText={strings.ButtonText.LowPriceWithEVERON}
          secondButtonColor="black"
          secondButtonText={strings.ButtonText.ChargingAsNonMember}
          twoButtonIcon={
            isRandomBoolean ? ICONS.naviIcons_Group : ICONS.naviIcons_Group2
          }
          onClick={() => {
            const { simpleCode } = chargerInfo as CheckChargerResponse;

            openEveronApp(simpleCode, false);
          }}
          secondButtonOnClick={() => {
            showPopup({
              popupType: "ChargingNonMemberPopup",
              popupProps: {
                isPopupActive,
                isRandomBoolean,
                handleClose: () => {
                  hidePopup();
                },
                onClick: () => {
                  onPageMove();
                },
              },
            });
          }}
        />
      </Layout>
    </>
  );
};

export default LandingPage;

const MainLogoWrap = styled.div`
  width: 100%;
  height: 94px;

  margin-bottom: 40px;
  text-align: center;
  color: ${colors.GRAY2};

  > :nth-child(1) {
    ${typo.HEADING_1}
  }
  > :nth-child(2) {
    ${typo.DISPLAY_3}
  }
`;

const ContentsWrap = styled.div<{ height?: number; center?: boolean }>`
  position: relative;
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : "100%")};
  max-height: ${({ height }) => height && height}px;
  ${({ center }) => {
    if (center) {
      return css`
        display: flex;
        justify-content: center;
        align-items: center;
      `;
    }
  }}
`;

const ImageWrap = styled.div<{
  backgroundSrc: string;
  width?: number;
  height?: number;
}>`
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  height: ${({ height }) => (height ? `${height}px` : "100%")};
  background: no-repeat center/cover
    url(${({ backgroundSrc }) => backgroundSrc});
`;

const StyledDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-43%, -50%);
  width: auto;
  height: 100%;
  margin-bottom: 30px;
`;

const StyledTitle = styled.p`
  white-space: pre-line;
  text-align: center;
  ${typo.HEADING_3};
  color: ${colors.GRAY4};
`;

const StyledCardContentsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledCard = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: 335px;
`;

const StyledCardTextWrap = styled.div`
  p:nth-child(1) {
    ${typo.HEADING_5};
    color: ${colors.GRAY4};
  }
  p:nth-child(2) {
    ${typo.BODY_7};
    color: ${colors.GRAY6};
  }
`;

const ApplicationWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
  padding: 60px 0;

  > p {
    ${typo.HEADING_3};
    color: ${colors.GRAY4};
  }
`;

const CustomColumn = styled(Column)`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;
